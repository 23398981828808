<template>
  <div>
    <hand :hand="hand" />
    <button class="btn" @click="newHand">new hand</button>
    <button class="btn" @click="draw">draw</button>
  </div>
</template>

<script>
import game from '@/game'
import Hand from '@/components/Hand'

export default {
  name: 'Home',

  components: {
    Hand
  },

  data: () => ({
    hand: []
  }),

  mounted() {
    this.newHand()
  },

  methods: {
    draw() {
      const i = Math.floor(Math.random() * game.deck.length)
      this.hand.push(game.deck[i])
    },

    async newHand() {
      this.hand = []
      await this.$nextTick()

      this.hand = game.deck.sort(() => 0.5 - Math.random()).slice(0, 3)
    }
  }
}
</script>
